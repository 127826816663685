.App {
  text-align: left;
  margin: auto;
  max-width: 1000px;
  font-size: 1em;
}
.Header {
  box-shadow: 0px 10px 10px #8E8E8E;
  background-color: #303F9F;
  padding: 10px;
  color: white;
  
}
nav ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
}

nav li {
  padding-left: 10px;
  padding-right: 10px;
  list-style-type: none;
  text-align: left;
  margin: 0;
}

h1, h2, h3 {
  color: #000000;
  text-align: center;
}

h1 {
  font-size: 5em;
  font-weight: 100;
  color: #FFFFFF;
  margin: 0px

}

h2 {
  font-size: 2.5em;
  font-weight: 200;
  text-align: left;
  margin-left: 5px;
}

p {
  margin-left: 10px;
}

h3 {
  font-size: 1.25em;
  font-weight: 300;
  text-align: left;
  margin: 0;
}

a {
  color: #303F9F;
}

nav a {
  color: #ffffff;
}


a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:active {
    text-decoration: underline;
    color: #ffffff;
    background: #303F9F;
}


.basic-grid {
  display: grid;
  grid-template-columns: 0.25fr 1fr;
  padding: 0;
}

.basic-col {
  position: relative;
  border-radius: 10px;
  background-color: white;  
  margin: 5px;
  box-shadow: 0px 0px 0px #8E8E8E;
  border: 0px solid grey;
}

.basic-col > img {
  width: 100%;
  border-radius: 9px;
  border: 0px solid white;
  margin: 0px;
}

.basic-col > p {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  margin: 0px;
}

/** DisplayItems **/

.items2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
}

.items3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0;
}

.item {
  position: relative;
  border-radius: 10px;
  background-color: white;  
  margin: 5px;
  box-shadow: 0px 1px 4px #8E8E8E;
  border: 1px solid grey;

}

.item:hover {
  box-shadow: 0px 1px 10px #8E8E8E;
  border: 1px solid black;

}

.item > p, h3 {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin: 0px;
}

.item > p {
  margin-bottom: 1em;
}

.item > img {
  width: 100%;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  border: 0px solid grey;
  margin: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.badge, p.badge {
  border: 1px solid black;
  background-color: #303030;
  color: white;
  font-size: 0.8em;
  margin: 0px;
}


@media (max-width: 600px) {
  .items2, .items3, .basic-grid {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0;
  }

  .item {
    position: relative;
    border-radius: 10px;
    background-color: white;
    border: 0px solid grey;
    padding: 0px;
    margin: 5px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 4px #8E8E8E;
  }

  .item > img {
    width: 100%;
    opacity: 0.7;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    border: 0px solid grey;
    margin: 0px;
  }

  .basic-col > img {
    width: 50%;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    border: 0px solid grey;
    margin: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }




} /** @media **/

.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.bottom-right > a {
  text-decoration: none;
  background-color: white;
  color: blue;
  border-radius: 10px;
  border: 0px solid grey;
  padding: 10px;
  font-size: 1.2em;
}

p.bottom-right {
  margin: 2px;
  margin-bottom: 12px;
  padding: 0px; 
}


/** End - DisplayItems **/

ul.publication {
  padding: 0px;
  margin-left: 10px;
}


li.publication {
  margin-top: 30px;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 0px;
  list-style-type: none;
  list-style-position: inside;
  

}


.publicationMore {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-end;

}

.publicationMore > li {
  font-size: 0.75em;
}

.publicationMore > li > a  {
  color: white;
  padding: 0px;
  background-color: #505050;
  width: 50px;
  text-align: center;
  border-radius: 10px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.publicationAbstract {
  margin-left: 10px;
  font-size: 0.75em;

}


button {
  font-size: 1em;
  color: white;
  margin: 5px;
  background-color: #E91E63;
  padding: 5px;
  min-width: 60px;
  border-radius: 5px;
  border: 0px;
  box-shadow: 0px 1px 4px #8E8E8E;
  opacity: 0.8;
}


footer {
  box-shadow: 0px 10px 10px #8E8E8E;
  background-color: #303F9F;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 50px;
  padding-bottom: 20px;
  color: white;
  font-size: 1em;
  margin-top: 20px;
  text-align: right;
}

footer > a {
  color: white;
}

